import React, { useState } from 'react';
import { useEffect, useRef } from "react";

import WordRow from './WordRow';
import TextBox from './TextInput';
import Keyboard from './Keyboard';

const PlayingBoard = ({states, word, wordList}) => {
	
	const [currColumn, setCurrColumn] = useState(0);
	const [guess, setGuess] = useState('');
	const [notAWord, setnotAWordMessage] = useState('');

	const correctWord = word.split('');
	const keyBoardStates = states.keyBoardStates;
	const rowStates = states.rowStates;
	const setGameOver = states.gameOver.setGameOver;
	const currentRow = states.rows.currentRow;
	const setCurrentRow = states.rows.setCurrentRow;

	const onSubmit = (guess) => {

		guess = guess.toUpperCase();

		if (!wordList.includes(guess)){
			setnotAWordMessage("Not a valid word!");
			return;
		}
		
		const guessArr = guess.split('');
		const newState = [];

		for (let i = 0; i < 5; i++) {
			if (correctWord.includes(guess[i])) {
				if (guess[i] === correctWord[i]) {
					newState.push({ letter: guessArr[i], state: 'correctPosition' });
					keyBoardStates[guess[i]].setKeyState({
						state: 'keysCorrectPosition',
						matched: true,
					});
				} else {
					newState.push({ letter: guessArr[i], state: 'correctLetter' });

					if (!keyBoardStates[guess[i]].keyState.matched)
						keyBoardStates[guess[i]].setKeyState({
							state: 'keysCorrectLetter',
							matched: false,
						});
				}
			} else {
				newState.push({ letter: guessArr[i], state: 'incorrectLetter' });
				keyBoardStates[guess[i]].setKeyState({
					state: 'keysIncorrect',
					matched: false,
				});
			}
		}
		if (guess === correctWord.join('')) {
			setGameOver('Win');
		}
		if (currentRow === 5) {
			setGameOver('Lose');
		}

		rowStates[currentRow][1](newState);
		setCurrentRow(currentRow + 1);
		setCurrColumn(0);
		setGuess('');
	};

	const onKeyboardPress = (letter) => {
		const newRowState = [];
		if (letter === 'ENTER') {
			if (currColumn < 5) return;
			onSubmit(guess);
			return;
		}

		rowStates[currentRow][0].forEach((block) => {
			newRowState.push({ ...block });
		});

		if (letter === '⌫') {
			if (currColumn === 0) return;
			newRowState[currColumn - 1].letter = '';
			newRowState[currColumn - 1].state = "noLetter";
			setCurrColumn(currColumn - 1);
			setGuess(guess.slice(0, currColumn - 1));
		} else {
			newRowState[currColumn == 5 ? 4 : currColumn].letter = letter;
			newRowState[currColumn == 5 ? 4 : currColumn].state = "letterPlaced";
			if (currColumn < 5) {
				setCurrColumn(currColumn + 1);
				setGuess(guess + letter);
			} else {
				setGuess(guess.slice(0, currColumn - 1) + letter);
			}
		}
		rowStates[currentRow][1](newRowState);

		if(notAWord != "")
			setnotAWordMessage("");
	};


	/*** CODE TAKEN FROM THE WEB  https://thewebdev.info/2021/05/24/how-to-listen-for-key-press-for-document-in-react-js/*/
	const useEventListener = (eventName, handler, element = window) => {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const eventListener = (event) => savedHandler.current(event);
		element.addEventListener(eventName, eventListener);
		return () => {
		element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
	};

	/*************************************************************** */

	const handler = (event) => {
		const key = event.key;
		
		const letter = key.toUpperCase();
		if(/^[A-Z]$/.test(letter) ||
			letter === "ENTER") {
			onKeyboardPress(letter);
			event.preventDefault();
		}
		else if(letter === "BACKSPACE"){
			onKeyboardPress('⌫');
			event.preventDefault();
		}
	}

  	useEventListener("keydown", handler);

	

	return (
		<div>
			{rowStates.map((row) => (
				<WordRow letterStates={row[0]} />
			))}
			<p className="notAWord">{notAWord}</p>
			<Keyboard
				keyBoardStates={keyBoardStates}
				onKeyboardPress={onKeyboardPress}
			></Keyboard>
		</div>
	);
};

export default PlayingBoard;
