
const EndGame = ({onClick, message, word}) => {

    return (
        <section className="endGame">
            <h3 className="messageHeader">{message}</h3>
            <p className="message">The word was <span >{word}!</span></p>
            <p className="message">Would you like to play again?</p>
            <button className="playAgainButton" onClick={onClick}>Play Again</button>
        </section>
    )
}

export default EndGame;