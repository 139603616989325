import './App.css';
import Header from './components/Header';
import PlayingBoard from './components/PlayingBoard';
import EndGame from './components/EndGame'
import React, { useState } from 'react';
import words from './words.json';

function App() {

	let wordList = words.words;
	const [word, setWord] = useState(getWord());
	let displayWord = "";

	// get word list

	function getWord(){
		const index = Math.floor(Math.random() * wordList.length);
		return wordList[index];
	}

	function GetKeyBoardState() {
		const [keyState, setKeyState] = useState({
			state: 'keysUnknown',
			matched: false,
		});
		return { keyState, setKeyState};
	}

	//Keyboard States
	function setUpKeyBoardStates() {
		return {
			A: GetKeyBoardState(),
			B: GetKeyBoardState(),
			C: GetKeyBoardState(),
			D: GetKeyBoardState(),
			E: GetKeyBoardState(),
			F: GetKeyBoardState(),
			G: GetKeyBoardState(),
			H: GetKeyBoardState(),
			I: GetKeyBoardState(),
			J: GetKeyBoardState(),
			K: GetKeyBoardState(),
			L: GetKeyBoardState(),
			M: GetKeyBoardState(),
			N: GetKeyBoardState(),
			O: GetKeyBoardState(),
			P: GetKeyBoardState(),
			Q: GetKeyBoardState(),
			R: GetKeyBoardState(),
			S: GetKeyBoardState(),
			T: GetKeyBoardState(),
			U: GetKeyBoardState(),
			V: GetKeyBoardState(),
			W: GetKeyBoardState(),
			X: GetKeyBoardState(),
			Y: GetKeyBoardState(),
			Z: GetKeyBoardState(),
			ENTER: GetKeyBoardState(),
			'⌫': GetKeyBoardState(),
		};
	}

	// Row States
	const rowStates = [];
	const initialState = [
		{ letter: '', status: 'white' },
		{ letter: '', status: 'white' },
		{ letter: '', status: 'white' },
		{ letter: '', status: 'white' },
		{ letter: '', status: 'white' },
	];

	rowStates.push(useState(initialState));
	rowStates.push(useState(initialState));
	rowStates.push(useState(initialState));
	rowStates.push(useState(initialState));
	rowStates.push(useState(initialState));
	rowStates.push(useState(initialState));

	//current row state
	const [currentRow, setCurrentRow] = useState(0);
	const [gameOver, setGameOver] = useState(false);

	// all states
	const states = {};
	states.rowStates = rowStates;
	states.rows = {currentRow, setCurrentRow};
	states.keyBoardStates = setUpKeyBoardStates();
	states.gameOver = {gameOver, setGameOver};

	function resetGame(){

		for (const state in states.keyBoardStates)
			states.keyBoardStates[state].setKeyState({
			state: 'keysUnknown',
			matched: false,
		});
		
		for (const row of states.rowStates)
			row[1](initialState);

		setCurrentRow(0);
		setGameOver(false);
		setWord(getWord());	
		displayWord = "";
	}

	let gameOverMessage = '';

	if (gameOver) {
		displayWord = word;
		const message = gameOver === "Win" ? "Congratulations!" : "Unlucky!";
		gameOverMessage = <EndGame onClick={resetGame} message={message} word={word}></EndGame>;
	}

	return (
		<body>
			<Header />
			<h3 className="displayWord">{displayWord}</h3>
			<PlayingBoard states={states} word={word.toUpperCase()} wordList={wordList}/>
			<section>{gameOverMessage}</section>
		</body>
	);
}

export default App;
