import LetterBox from './LetterBox';

const WordRow = ({ letterStates }) => {
	return (
		<div className='WordRow'>
			{letterStates.map((letter) => (
				<LetterBox letter={letter} />
			))}
		</div>
	);
};

export default WordRow;
