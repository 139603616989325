const Keyboard = ({ keyBoardStates, onKeyboardPress }) => {
	const topRow = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
	const middleRow = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
	const bottomRow = ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫'];

	return (
		<div className="keyboard">
			<div className='keyRow'>
				{topRow.map((key) => (
					<button
						onClick={(e) => onKeyboardPress(e.target.innerText)}
						className={`keys ${keyBoardStates[key].keyState.state}`}
					>
						{key}
					</button>
				))}
			</div>
			<div className='keyRow'>
				{middleRow.map((key) => (
					<button
						onClick={(e) => onKeyboardPress(e.target.innerText)}
						className={`keys ${keyBoardStates[key].keyState.state}`}
					>
						{key}
					</button>
				))}
			</div>
			<div className='keyRow'>
				{bottomRow.map((key) => (
					<button
						onClick={(e) => onKeyboardPress(e.target.innerText)}
						className={`keys ${keyBoardStates[key].keyState.state}`}
					>
						{key}
					</button>
				))}
			</div>
		</div>
	);
};

export default Keyboard;
